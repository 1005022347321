import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";
import Moment from "moment";
import { formatObject } from "../../helpers/utility";
import { isArray } from "lodash";
const formatResponse = (res) => {
  return res.map((item) => ({
    _id: item._id,
    name: `${item.firstName} ${item.lastName || ""}`,
  }));
};

const formatExportData = (data) => {
  let finalItem = [];
  data.forEach((item) => {
    if (Object.keys(item.psm) && Object.keys(item.psm).length > 0) {
      Object.keys(item.psm).forEach((day) => {
        finalItem.push({
          userAccessionNo: item.user && item.user.accessionNo,
          imgName:
            item.psm[day].split("/")[item.psm[day].split("/").length - 1] ||
            "No files uploaded",
          dayName: day,
          admin:
            (item.updatedBy &&
              `${item.updatedBy.firstName} ${item.updatedBy.lastName}`) ||
            "",
          uploadDate: Moment(item.updatedOn).format("YYYY-MM-DD | hh:mmA"),
        });
      });
    }
  });
  return finalItem;
};

const setupResponse = (resp) => {
  const apiResponse = JSON.parse(JSON.stringify(resp));
  let finalItem = [];
  apiResponse.forEach((item) => {
    if (item.users && item.users.length) {
      item.users.forEach((user) => {
        finalItem.push({
          _id: item._id,
          user,
          psm: item.psm,
          updatedBy: item.updatedBy,
          updatedOn: item.updatedOn,
          createdOn: item.createdOn,
        });
      });
    } else {
      finalItem.push({
        _id: item._id,
        user: {},
        psm: item.psm,
        updatedBy: item.updatedBy,
        updatedOn: item.updatedOn,
        createdOn: item.createdOn,
      });
    }
  });

  return finalItem;
};

export function* fetchPsmConfig() {
  yield takeEvery(actions.FETCH_PSM_CONFIG, function* (action) {
    try {
      yield put({
        type: actions.PSM_LOADER,
        loading: true,
      });
      // const roles = yield call(API.common.roles);
      // const grades = yield call(API.common.grades);
      const schools = yield call(API.common.schools);
      // const subjects = yield call(API.common.subjects);

      yield put({
        type: actions.FETCH_PSM_CONFIG_SUCCESS,
        roles: /*roles && roles.data.roles ? roles.data.roles :*/[],
        grades: /*grades && grades.data.grades ? grades.data.grades : */[],
        schools: schools && schools.data.schools ? schools.data.schools : [],
        subjects: [],
        // subjects && subjects.data.subjects ? subjects.data.subjects : [],
      });
      yield put({
        type: actions.PSM_LOADER,
        loading: false,
      });
    } catch (err) {
      yield put({
        type: actions.PSM_LOADER,
        loading: false,
      });
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchUserList() {
  yield takeEvery(actions.FETCH_USERLIST, function* (action) {
    try {
      yield put({
        type: actions.PSM_LOADER,
        loading: true,
      });
      const resp = yield call(API.users.list, formatObject(action.payload));

      if (!resp.data) {
        Notification("error", "No users found for this combination!");
      }

      yield put({
        type: actions.FETCH_USERLIST_SUCCESS,
        users: resp.data ? formatResponse(resp.data?.data || []) : [],
      });
      yield put({
        type: actions.PSM_LOADER,
        loading: false,
      });
    } catch (err) {
      yield put({
        type: actions.PSM_LOADER,
        loading: false,
      });
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* savePsm() {
  yield takeEvery(actions.SAVE_PSM, function* (action) {
    try {
      let message = "Updated";

      let data = new FormData();
      data.append(
        "monday",
        action.psmList.monday ? action.psmList.monday.originFileObj : null
      );
      data.append(
        "tuesday",
        action.psmList.tuesday ? action.psmList.tuesday.originFileObj : null
      );
      data.append(
        "wednesday",
        action.psmList.wednesday ? action.psmList.wednesday.originFileObj : null
      );
      data.append(
        "thursday",
        action.psmList.thursday ? action.psmList.thursday.originFileObj : null
      );
      data.append(
        "friday",
        action.psmList.friday ? action.psmList.friday.originFileObj : null
      );
      data.append(
        "saturday",
        action.psmList.saturday ? action.psmList.saturday.originFileObj : null
      );
      data.append(
        "sunday",
        action.psmList.sunday ? action.psmList.sunday.originFileObj : null
      );
      // if (action.grade) data.append("grade", action.grade);
      // if (action.role) data.append("role", action.role);
      if (action?.school) data.append("school", action.school);
      // action.subject ? data.append("subject", action.subject) : null;
      data.append("userList", JSON.stringify(action.userList));
      if (action?.classroom) data.append("classroom", action.classroom);
      yield put({
        type: actions.PSM_LOADER,
        loading: true,
      });
      const response = yield call(API.psm.save, data);

      yield put({
        type: actions.PSM_LOADER,
        loading: false,
      });
      if (response.success) {
        yield put({
          type: actions.SAVE_PSM_SUCCESS,
        });
        Notification("success", response?.message?.data?.message || "");
        if (action?.cb) yield call(action.cb, true);
      } else {
        if (action?.cb) yield call(action.cb, false);
        Notification("error", response?.message?.error?.message || "");
      }
    } catch (err) {
      yield put({
        type: actions.PSM_LOADER,
        loading: false,
      });
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
      if (action?.cb) yield call(action.cb, false);
    }
  });
}

export function* deletePsm() {
  // yield takeEvery(actions.DELETE_GRADE, function* (action) {
  //   try {
  //     yield call(API.grades.update, action.payload, action.gradeId);
  //     const resp = yield call(API.grades.list);
  //     yield put({
  //       type: actions.FETCH_GRADES_SUCCESS,
  //       grades: resp.data.grades,
  //     });
  //     Notification(
  //       'success', 'Grade deleted successfully',
  //     );
  //     yield call(action.spinHandler, false);
  //   } catch (err) {
  //     yield call(action.spinHandler, false);
  //     Notification(
  //       'error',
  //       err && err.error ? err.error.message: 'Session timed out. Please login again',
  //     );
  //   }
  // });
}

export function* fetchPSMExport() {
  yield takeEvery(actions.FETCH_PSM_EXPORT, function* (action) {
    try {
      const response = yield call(API.psm.export, action.payload);

      yield put({
        type: actions.FETCH_PSM_EXPORT_SUCCESS,
        // payload: formatExportData(setupResponse(response.data)),
        payload: response.data
      });
      if (action?.cb)
        yield call(
          action.cb, response.data
          //isArray(response?.data) ? response?.data?.length > 0 : false
        );
    } catch (err) {
      if (action?.cb) yield call(action.cb, false);
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Error fetching psm export data"
      );
    }
  });
}

export function* listPsm() {
  yield takeEvery(actions.FETCH_PSM_LIST, function* (action) {
    try {
      const resp = yield call(API.psm.listPsm, action.payload);

      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });

      if (resp) {
        yield put({
          type: actions.FETCH_PSM_LIST_SUCCESS,
          data: setupResponse(resp),
        });
      }
    } catch (err) {
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchPsmConfig),
    fork(fetchUserList),
    fork(savePsm),
    fork(deletePsm),
    fork(listPsm),
    fork(fetchPSMExport),
  ]);
}
