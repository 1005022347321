import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";
import { formatObject } from "../../helpers/utility";
import actionsClassroom from "../MyClassroom/actions";
import actionsCourse from "../Courses/actions";
import { isArray } from "lodash-es";

export function* fetchWebsites() {
  yield takeEvery(actions.FETCH_WEBSITES, function*(action) {
    try {
      console.log('here',action);
      const resp = yield call(API.websites.list, action.payload);
      yield put({
        type: actions.FETCH_WEBSITES_SUCCESS,
        websites: resp.data.websites,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveWebsite() {
  yield takeEvery(actions.SAVE_WEBSITE, function*(action) {
    try {
      let message;
      let data = new FormData();
      if (action.payload.file && action.payload.file.originFileObj) {
        data.append("file", action.payload.file.originFileObj);
        data.append("filename", action.payload.file.name);
      }
      data.append("title", action.payload.title);
      // data.append("school", JSON.stringify(action.payload.school));
      if (
        isArray(action.payload.organization) &&
        action.payload.organization.length > 0
      )
        data.append(
          "organizations",
          JSON.stringify(action.payload.organization)
        );
      data.append("url", action.payload.url);
      data.append("allowedUrls", JSON.stringify(action.payload.allowedUrls));
      if (action?.payload?.qualityEngagementFactor >= 0)
        data.append(
          "qualityEngagementFactor",
          action.payload.qualityEngagementFactor
        );
      // if (action.payload.description) {
      data.append("description", action.payload.description);
      // }
      data.append("hashtag", JSON.stringify(action.payload.tags));
      data.append("isApproved", action.payload.isApproved);
      data.append("isPublic", action.payload.isPublic);
      if (action?.payload?.explore || action?.payload?.explore == false)
        data.append("explore", action.payload.explore);
      data.append("module", action.payload.module);
      data.append("age", action.payload.age);

      if (action.websiteId) {
        yield call(API.websites.update, data, action.websiteId);
        message = "Website updated successfully";
      } else {
        const res = yield call(API.websites.add, data);
        const resId =
          res.data && res.data.websitedata && res.data.websitedata._id;
        if (resId) {
          const updateParam = {
            type: "website",
            resourceId: resId,
          };
          if (action.payload.classroom_id) {
            const resp = yield call(
              API.myClassroom.addExisting,
              action.payload.classroom_id,
              updateParam
            );
          } else if (action.payload.course_id) {
            const resp = yield call(
              API.courses.addExisting,
              action.payload.course_id,
              updateParam
            );
          }
        }
        message = "Website added successfully";
      }
      if (action.payload.classroom_id) {
        const respClass = yield call(
          API.myClassroom.listSingleClassroom,
          action.payload.classroom_id
        );
        yield put({
          type: actionsClassroom.FETCH_SINGLE_CLASSROOM_SUCCESS,
          singleClassroom: respClass.data.classroom,
        });
      } else if (action.payload.course_id) {
        const respCourse = yield call(
          API.courses.details,
          action.payload.course_id
        );
        yield put({
          type: actionsCourse.FETCH_COURSE_DETAILS_SUCCESS,
          course: respCourse.data,
        });
      }
      const resp = yield call(API.websites.list);
      yield put({
        type: actions.FETCH_WEBSITES_SUCCESS,
        websites: resp.data.websites,
      });
      yield call(action.cb);
      Notification("success", message);
    } catch (err) {
      yield call(action.cb, "loader");
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteWebsite() {
  yield takeEvery(actions.DELETE_WEBSITE, function*(action) {
    try {
      yield call(API.websites.delete, action.websiteId);
      const resp = yield call(API.websites.list);
      yield put({
        type: actions.FETCH_WEBSITES_SUCCESS,
        websites: resp.data.websites,
      });
      Notification("success", "Website deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchBonusWebsites() {
  yield takeEvery(actions.FETCH_BONUS_WEBSITES, function*(action) {
    try {
      const resp = yield call(API.bonusWebsite.list);
      yield put({
        type: actions.FETCH_BONUS_WEBSITES_SUCCESS,
        bonusWebsites: resp.data.bonuswebsites,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveBonusWebsite() {
  yield takeEvery(actions.SAVE_BONUS_WEBSITE, function*(action) {
    try {
      let message;
      let data = new FormData();
      if (action.payload.file && action.payload.file.originFileObj) {
        data.append("file", action.payload.file.originFileObj);
        // data.append("filename", action.payload.file.name);
      }
      data.append("title", action.payload.title);
      data.append("url", action.payload.url);
      data.append("users", JSON.stringify(action.payload.users));
      if (action?.payload?.classroom)
        data.append("classroom", action.payload.classroom);
      data.append("allowedUrls", JSON.stringify(action.payload.allowedUrls));
      if (action.payload.grade) data.append("grade", action.payload.grade);
      if (action.payload.role) data.append("role", action.payload.role);
      if (action.payload.techSupportId)
        data.append("techSupportId", action.payload.techSupportId);
      if (action.payload.creationMethod)
        data.append("creationMethod", action.payload.creationMethod);
      if (action?.payload?.qualityEngagementFactor >= 0)
        data.append(
          "qualityEngagementFactor",
          action.payload.qualityEngagementFactor
        );
      if (action.payload.tags)
        data.append("hashtag", JSON.stringify(action.payload.tags));
      if (action.payload.description) {
        data.append("description", action.payload.description);
      }
      if (action?.payload?.school) data.append("school", action.payload.school);

      if (action.payload.organization) {
        data.append("organization", action.payload.organization);
      }

      if (action.websiteId) {
        yield call(API.bonusWebsite.update, data, action.websiteId);
        message = "Website updated successfully";
      } else {
        yield call(API.bonusWebsite.add, data);
        message = "Website created successfully";
      }
      const websites = yield call(API.bonusWebsite.list);
      yield put({
        type: actions.FETCH_BONUS_WEBSITES_SUCCESS,
        bonusWebsites: websites.data.bonuswebsites,
      });
      if (action.payload.techSupportId) {
        yield call(action.cb, true);
      } else yield call(action.cb);
      Notification("success", message);
    } catch (err) {
      console.log(err, "error");
      if (action.cb) {
        if (action.payload.techSupportId) {
          yield call(action.cb, false);
        }
        yield call(action.cb, "loader");
      }
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteBonusWebsite() {
  yield takeEvery(actions.DELETE_BONUS_WEBSITE, function*(action) {
    try {
      yield call(API.bonusWebsite.delete, action.websiteId);
      const resp = yield call(API.bonusWebsite.list);
      yield put({
        type: actions.FETCH_BONUS_WEBSITES_SUCCESS,
        bonusWebsites: resp.data.bonuswebsites,
      });
      Notification("success", "Website deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchConfigs() {
  yield takeEvery(actions.FETCH_BONUS_CONFIGS, function*(action) {
    try {
      const roles = yield call(API.common.roles);
      const schools = yield call(API.common.schools);
      // const grades = yield call(API.common.grades);
      yield put({
        type: actions.FETCH_BONUS_CONFIGS_SUCCESS,
        roles: roles && roles.data.roles ? roles.data.roles : [],
        schools: schools && schools.data.schools ? schools.data.schools : [],
        grades: /*grades && grades.data.grades ? grades.data.grades : */ [],
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchUsers() {
  yield takeEvery(actions.FETCH_BONUS_USERS, function*(action) {
    try {
      const users = yield call(API.users.list, action.payload);
      yield put({
        type: actions.FETCH_BONUS_USERS_SUCCESS,
        users: users && users.data?.students ? users.data?.students : [],
      });
      yield call(action.spinner);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSchool() {
  yield takeEvery(actions.FETCH_SCHOOLS, function*(action) {
    try {
      const schools = yield call(API.common.schools);
      yield put({
        type: actions.FETCH_SCHOOL_SUCCESS,
        schools: schools && schools.data.schools ? schools.data.schools : [],
      });
      // yield call(action.spinner, false);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchOrganization() {
  yield takeEvery(actions.FETCH_ORGANIZATIONS, function*(action) {
    try {
      const organizations = yield call(API.common.organizations);
      yield put({
        type: actions.FETCH_SCHOOL_SUCCESS,
        organizations:
          organizations && organizations.data.organizations
            ? organizations.data.organizations
            : [],
      });
      // yield call(action.spinner, false);
    } catch (err) {
      // Notification(
      //   "error",
      //   err && err.error
      //     ? err.error.message
      //     : "Session timed out. Please login again"
      // );
    }
  });
}

export function* fetchSingleBonus() {
  yield takeEvery(actions.GET_USER_RECORD_WEB, function*(action) {
    try {
      const resp = yield call(API.bonusWebsite.listSingle, action.id);
      yield put({
        type: actions.GET_USER_RECORD_WEB_SUCCESS,
        bonusBook: resp.data.bonusWebsiteFound,
      });
      yield call(action.callBack);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchSchool),
    fork(fetchOrganization),
    fork(fetchWebsites),
    fork(fetchBonusWebsites),
    fork(saveWebsite),
    fork(saveBonusWebsite),
    fork(deleteWebsite),
    fork(deleteBonusWebsite),
    fork(fetchConfigs),
    fork(fetchUsers),
    fork(fetchSingleBonus),
  ]);
}
