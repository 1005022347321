import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

const { setMenuItem, setRoles, fetchRole } = actions;

export function* fetchRoles() {
  yield takeEvery(actions.GET_ROLES, function*(action) {
    try {
      const resp = yield call(API.role.list);
      if (resp.statusCode === 200) {
        yield put(setRoles(resp.data?.roles));
      } else {
        Notification("error", resp.message);
      }
      yield call(action.callBack, false);
    } catch (err) {
      yield call(action.callBack, false);
      Notification(
        "error",
        err && err.message
          ? "User Does Not Have Permission To Perform This Action"
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchMenuList() {
  yield takeEvery(actions.GET_ALL_MENU_ITEM, function*(action) {
    try {
      const resp = yield call(API.role.listMenu);
      if (resp.statusCode === 200) {
        yield put(setMenuItem(resp.data.menus));
      } else {
        Notification("error", resp.message);
      }
      yield call(action.callBack, false);
    } catch (err) {
      yield call(action.callBack, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* addRole() {
  yield takeEvery(actions.ADD_ROLE, function*(action) {
    try {
      const resp = yield call(API.role.addRole, action.payload);
      if (resp.data) {
        yield put(fetchRole(action.callBack));
        Notification("success", resp.data.message);
      }
    } catch (err) {
      yield call(action.callBack, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteRole() {
  yield takeEvery(actions.DELETE_ROLE, function*(action) {
    try {
      yield call(API.role.delete, action.id);
      yield put(fetchRole(action.callBack));
      Notification("success", "Role deleted successfully");
    } catch (err) {
      yield call(action.callBack, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* updateRole() {
  yield takeEvery(actions.UPDATE_ROLE, function*(action) {
    try {
      yield call(API.role.update, action.payload, action.id);
      yield put(fetchRole(action.callBack));
      Notification("success", "Role updated");
    } catch (err) {
      yield call(action.callBack, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchMenuList)]);
  yield all([fork(addRole)]);
  yield all([fork(fetchRoles)]);
  yield all([fork(deleteRole)]);
  yield all([fork(updateRole)]);
}
