import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import courseActions from "../../redux/Courses/actions";
import actionsCourse from "../Courses/actions";
import myClassroomActions from "../MyClassroom/actions";
import actionsClassroom from "../MyClassroom/actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchBooks() {
  yield takeEvery(actions.FETCH_BOOKS, function*(action) {
    try {
      // const resp = yield call(API.books.list);
      const resp = yield call(API.books.list, action?.payload);
      console.log("+++++++++++here------", resp);
      yield put({
        type: actions.FETCH_BOOKS_SUCCESS,
        books: resp.data.libraries,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSingleBook() {
  yield takeEvery(actions.GET_USER_RECORD, function*(action) {
    try {
      const resp = yield call(API.books.details, action.id);
      yield put({
        type: actions.FETCH_BOOK_DETAILS_SUCCESS,
        book: resp.data?.library,
      });
      yield call(action.callBack);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveBook() {
  yield takeEvery(actions.SAVE_BOOK, function*(action) {
    try {
      let res, message;
      let data = new FormData();
      if (action.payload.cover) {
        data.append(
          "cover",
          action.payload.cover.originFileObj
            ? action.payload.cover.originFileObj
            : ""
        );
      }
      if (action.payload.file) {
        data.append(
          "file",
          action.payload.file.originFileObj
            ? action.payload.file.originFileObj
            : ""
        );
      }
      data.append("type", action.payload.type);
      data.append("title", action.payload.title);
      data.append("publication", action.payload.publication);
      data.append("description", action.payload.description);
      // data.append("role", action.payload.role);
      // data.append("subject", action.payload.subject);
      data.append("language", action.payload.language);
      // data.append("grade", action.payload.grade);
      data.append("bookId", action.payload.bookId);
      data.append("versions", JSON.stringify(action.payload.versions));

      if (action.bookId) {
        res = yield call(API.books.update, data, action.bookId);
        message = "Content updated successfully";
      } else {
        res = yield call(API.books.add, data);
        message = "Content added successfully";
      }
      Notification("success", message);
      if (action.payload.bookId) {
        yield put(
          push(`/dashboard/books_videos/details/${action.payload.bookId}`)
        );
      } else {
        yield put(push(`/dashboard/books_videos`));
      }
    } catch (err) {
      yield call(action.cb, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteBook() {
  yield takeEvery(actions.DELETE_BOOK, function*(action) {
    try {
      yield call(API.books.delete, action.bookId);
      const resp = yield call(API.books.list);
      yield put({
        type: actions.FETCH_BOOKS_SUCCESS,
        books: resp.data.resources,
      });
      Notification("success", "Book deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchBookConfigs() {
  yield takeEvery(actions.FETCH_BOOK_CONFIGS, function*(action) {
    try {
      const schools = yield call(API.common.schools);
      yield put({
        type: actions.FETCH_BOOK_CONFIGS_SUCCESS,
        schools: schools && schools.data.schools ? schools.data.schools : [],
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* addBookVersionLibrary() {
  yield takeEvery(actions.ADD_BOOK_VERSION_LIBRARY, function*(action) {
    try {
      let data = new FormData();
      if (action.payload.cover === undefined) {
        data.append("cover", null);
      } else {
        if (action.payload.cover.originFileObj) {
          data.append(
            "cover",
            action.payload.cover.originFileObj
              ? action.payload.cover.originFileObj
              : ""
          );
        }
      }

      if (action.payload.file.originFileObj) {
        data.append(
          "file",
          action.payload.file.originFileObj
            ? action.payload.file.originFileObj
            : ""
        );
      }
      if (action.payload.title) {
        data.append("title", action.payload.title);
      }
      if (action.payload.qualityEngagementFactor)
        data.append(
          "qualityEngagementFactor",
          action.payload.qualityEngagementFactor
        );
      if (action.payload.description) {
        data.append("description", action.payload.description);
      }
      if (action.payload.type) {
        data.append("type", action.payload.type);
      }
      data.append("isPublic", action.payload.isPublic);
      if (action.payload.type === "book") {
        data.append("publication", action.payload.publication);
        data.append("isbn", action.payload.isbn);
      }
      if (action.payload.type === "lesson" && action.payload.test != null) {
        data.append("test", action.payload.test);
      }
      if (
        action.payload.organizations &&
        action.payload.organizations.length > 0
      ) {
        data.append(
          "organizations",
          JSON.stringify(action.payload.organizations)
        );
      }
      if (action.payload.hashtag && action.payload.hashtag.length > 0) {
        data.append("hashtag", JSON.stringify(action.payload.hashtag));
      }
      // if (action.payload.age) {
      data.append("age", action?.payload?.age || "");
      // }
      if (action?.payload?.fromLibrary) {
        data.append("fromLibrary", action.payload.fromLibrary);
      }
      let message;
      if (action.payload.fileId) {
        yield call(API.books.update, data, action.payload.fileId);
        message = "File updated successfully";
      } else {
        const res = yield call(API.books.add, data);
        message = "File added successfully";
      }
      const resp = yield call(API.books.search);
      yield put({
        type: actions.FETCH_BOOKS_SUCCESS,
        books: resp.data.resources,
      });
      yield call(action.spinHandler);
      Notification("success", message);
    } catch (err) {
      const mes = err?.error?.message || err?.error || "";
      yield call(action.spinHandler, "loader");
      Notification("error", mes);
    }
  });
}

export function* addBookVersion() {
  yield takeEvery(actions.ADD_BOOK_VERSION, function*(action) {
    try {
      let data = new FormData();
      if (action.payload.cover === undefined) {
        data.append("cover", null);
      } else {
        if (action.payload.cover.originFileObj) {
          data.append(
            "cover",
            action.payload.cover.originFileObj
              ? action.payload.cover.originFileObj
              : ""
          );
        }
      }

      if (action.payload.file.originFileObj) {
        data.append(
          "file",
          action.payload.file.originFileObj
            ? action.payload.file.originFileObj
            : ""
        );
      }
      if (action.payload.title) {
        data.append("title", action.payload.title);
      }
      if (action.payload.description) {
        data.append("description", action.payload.description);
      }
      if (action.payload.type) {
        data.append("type", action.payload.type);
      }
      if (action.payload.organization) {
        data.append("organizations", action.payload.organizations);
      }
      data.append("isPublic", action.payload.isPublic);
      if (action.payload.type === "book") {
        data.append("publication", action.payload.publication);
        data.append("isbn", action.payload.isbn);
      }
      if (action.payload.type === "lesson" && action.payload.test != null) {
        data.append("test", action.payload.test);
      }
      if (
        action.payload.organizations &&
        action.payload.organizations.length > 0
      ) {
        data.append(
          "organizations",
          JSON.stringify(action.payload.organizations)
        );
      }
      if (action.payload.week && action.payload.week.week) {
        data.append("week", action.payload.week.week);
      }
      if (action.payload.hashtag && action.payload.hashtag.length > 0) {
        data.append("hashtag", JSON.stringify(action.payload.hashtag));
      }
      // if(action.payload.fileId){
      // data.append("fileId", action.payload.fileId);
      // }
      // data.append("bookId", action.payload.bookId);
      if (action.payload.age) {
        data.append("age", action.payload.age);
      }
      if (action.payload?.qualityEngagementFactor)
        data.append(
          "qualityEngagementFactor",
          action.payload.qualityEngagementFactor
        );

      let message;
      if (action.payload.fileId) {
        yield call(API.books.update, data, action.payload.fileId);
        message = "File updated successfully";
      } else {
        const res = yield call(API.books.add, data);
        if (action.payload) {
          const updateParam = {
            ...(action.payload.week || {}),
            type: action.payload.type || "",
            resourceId: res.data.data._id,
          };
          if (action.payload && action.payload.course_id) {
            yield call(
              API.courses.addExisting,
              action.payload.course_id,
              updateParam
            );
            const respCourse = yield call(
              API.courses.details,
              action.payload.course_id
            );
            yield put({
              type: actionsCourse.FETCH_COURSE_DETAILS_SUCCESS,
              course: respCourse.data,
            });
          } else {
            yield call(
              API.myClassroom.addExisting,
              action.payload.classroom_id,
              updateParam
            );
            const respClass = yield call(
              API.myClassroom.listSingleClassroom,
              action.payload.classroom_id
            );
            yield put({
              type: actionsClassroom.FETCH_SINGLE_CLASSROOM_SUCCESS,
              singleClassroom: respClass.data.classroom,
            });
          }
          // if (
          //   action.payload.platform &&
          //   action.payload.platform === "courseDetails"
          // ) {
          // const respCourse = yield call(
          //   API.courses.details,
          //   action.payload.course_id
          // );
          // yield put({
          //   type: actionsCourse.FETCH_COURSE_DETAILS_SUCCESS,
          //   course: respCourse.data,
          // });
          // } else if (
          //   action.payload.platform &&
          //   action.payload.platform === "myClassroom"
          // ) {
          // const respClass = yield call(
          //   API.myClassroom.listSingleClassroom,
          //   action.payload.classroom_id
          // );
          // yield put({
          //   type: actionsClassroom.FETCH_SINGLE_CLASSROOM_SUCCESS,
          //   singleClassroom: respClass.data.classroom,
          // });
          // } else {
          // }
        }
        message = "File added successfully";
      }
      if (action.payload.actionType) {
        if (action.payload?.actionType === "editCourse") {
          const singleCourse = yield call(API.courses.details, action.courseId);
          yield put({
            type: courseActions.FETCH_COURSE_DETAILS_SUCCESS,
            course: singleCourse.data,
          });
        } else {
          const resp = yield call(
            API.myClassroom.listSingleClassroom,
            action.payload.classroomId
          );
          yield put({
            type: myClassroomActions.FETCH_SINGLE_CLASSROOM_SUCCESS,
            singleClassroom: resp.data.classroom,
          });
        }
      } else {
        const resp = yield call(API.books.search);
        yield put({
          type: actions.FETCH_BOOKS_SUCCESS,
          books: resp.data.resources,
        });
      }
      yield call(action.spinHandler);
      Notification("success", message);
    } catch (err) {
      yield call(action.spinHandler, "loader");
      Notification(
        "error",
        err && err.error
          ? err.error.message
            ? err.error.message
            : err.error
          : ""
        // ? err.error.message
        // : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteBookVersion() {
  yield takeEvery(actions.DELETE_BOOK_VERSION, function*(action) {
    try {
      yield call(API.books.version.delete, action.versionId);
      const resp = yield call(API.books.search);
      yield put({
        type: actions.FETCH_BOOKS_SUCCESS,
        books: resp.data.resources,
      });

      // const details = yield call(API.books.details, action.bookId);
      // yield put({
      //   type: actions.FETCH_BOOK_DETAILS_SUCCESS,
      //   book: details.data,
      // });
      Notification("success", "Content deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchBonusBooks() {
  yield takeEvery(actions.FETCH_BONUS_BOOKS, function*(action) {
    try {
      const resp = yield call(API.bonusContent.list);
      // const resp = yield call(API.bonusContent.search, action.payload);
      yield put({
        type: actions.FETCH_BONUS_BOOKS_SUCCESS,
        bonusContents: resp.data.resources,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSingleBonusBook() {
  yield takeEvery(actions.GET_USER_RECORD_BONUS, function*(action) {
    try {
      const resp = yield call(API.bonusContent.details, action.id);
      yield put({
        type: actions.FETCH_BONUS_BOOK_DETAILS_SUCCESS,
        bonusBook: resp.data,
      });
      yield call(action.callBack);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveBonusBook() {
  yield takeEvery(actions.SAVE_BONUS_BOOK, function*(action) {
    try {
      let res, message;
      let data = new FormData();
      if (action.payload.cover) {
        data.append(
          "cover",
          action.payload.cover.originFileObj
            ? action.payload.cover.originFileObj
            : ""
        );
      }
      if (action.payload.file) {
        data.append(
          "file",
          action.payload.file.originFileObj
            ? action.payload.file.originFileObj
            : ""
        );
      }
      data.append("type", action.payload.type);
      data.append("title", action.payload.title);
      data.append("publication", action.payload.publication);
      data.append("description", action.payload.description);
      data.append("role", action.payload.role);
      data.append("isbn", action.payload?.isbn);
      if (action.payload.qualityEngagementFactor)
        data.append(
          "qualityEngagementFactor",
          action.payload?.qualityEngagementFactor
        );
      data.append("subject", action.payload.subject);
      data.append("language", action.payload.language);
      data.append("school", action.payload?.school);
      data.append("bookId", action.payload.bookId);
      data.append("users", JSON.stringify(action.payload.users));
      if (action.payload.tags)
        data.append("hashtag", JSON.stringify(action.payload.tags));
      if (action.payload.techSupportId)
        data.append("techSupportId", action.payload.techSupportId);
      if (action.payload.creationMethod)
        data.append("creationMethod", action.payload.creationMethod);

      if (action.bookId) {
        yield call(API.bonusContent.update, data, action.bookId);
        message = " My Library updated successfully";
      } else {
        yield call(API.bonusContent.add, data);
        message = "My Library added successfully";
      }
      if (action.payload.techSupportId) {
        yield call(action.cb, true);
      }
      Notification("success", message);
      // yield put(push("/dashboard/bonus_content"));
    } catch (err) {
      if (action.cb) yield call(action.cb, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchUsers() {
  yield takeEvery(actions.FETCH_BONUS_CONTENT_USERS, function*(action) {
    try {
      const payload = {};
      if (action?.payload) payload.classroom = action?.payload;
      if (action?.school) payload.school = action?.school;
      const res = yield call(API.users.classroomList, payload);
      yield put({
        type: actions.FETCH_BONUS_CONTENT_USERS_SUCCESS,
        users: res && res.data?.students ? res.data?.students : [],
      });
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteBonusBook() {
  yield takeEvery(actions.DELETE_BONUS_BOOK, function*(action) {
    try {
      yield call(API.bonusContent.delete, action.bookId);
      const resp = yield call(API.bonusContent.list);
      yield put({
        type: actions.FETCH_BONUS_BOOKS_SUCCESS,
        bonusContents: resp.data.bonusContents,
      });
      Notification("success", "My Library deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error ? err.error.message : "Request failed"
      );
    }
  });
}

export function* addBonusContentVersion() {
  yield takeEvery(actions.ADD_BONUS_BOOK_VERSION, function*(action) {
    try {
      let data = new FormData();
      if (action.payload.cover) {
        data.append(
          "cover",
          action.payload.cover.originFileObj
            ? action.payload.cover.originFileObj
            : ""
        );
      }
      if (action.payload.file) {
        data.append(
          "file",
          action.payload.file.originFileObj
            ? action.payload.file.originFileObj
            : ""
        );
      }
      // if (action.payload.school && action.payload.school.length > 0) {
      //   data.append("school", action.payload.school);
      // }
      data.append("type", action.payload.type);
      data.append("title", action.payload.title);
      // if (action.payload.publication) {
      data.append("publication", action?.payload?.publication || "");
      // }
      data.append("description", action.payload.description);
      data.append("hashtag", JSON.stringify(action.payload.hashtag));
      data.append("language", action.payload.language);
      if (action?.payload?.qualityEngagementFactor >= 0)
        data.append(
          "qualityEngagementFactor",
          action.payload.qualityEngagementFactor
        );
      // if (action.payload.isbn) {
      data.append("isbn", action?.payload?.isbn || "");
      // }
      if (action?.payload?.classroom)
        data.append("classroom", action.payload.classroom);
      if (action?.payload?.school) data.append("school", action.payload.school);
      data.append("fileId", action.payload.fileId);
      data.append("bookId", action.payload.bookId);
      data.append("files", JSON.stringify(action.payload.files));
      data.append("users", JSON.stringify(action.payload.users));
      let message;
      if (action.payload.fileId) {
        yield call(API.bonusContent.update, data, action.payload.fileId);
        message = "My Library updated successfully";
      } else {
        yield call(API.bonusContent.add, data);
        message = "My Library added successfully";
      }

      const resp = yield call(API.bonusContent.list);
      yield put({
        type: actions.FETCH_BONUS_BOOKS_SUCCESS,
        bonusContents: resp.data.resources,
      });
      yield call(action.spinHandler);
      Notification("success", message);
    } catch (err) {
      yield call(action.spinHandler, "loader");
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteBonusBookVersion() {
  yield takeEvery(actions.DELETE_BONUS_BOOK_VERSION, function*(action) {
    try {
      yield call(API.bonusContent.version.delete, action.versionId);
      const resp = yield call(API.bonusContent.list);
      yield put({
        type: actions.FETCH_BONUS_BOOKS_SUCCESS,
        bonusContents: resp.data.resources,
      });
      Notification(
        "success",
        `The ${
          action.resourceType != undefined ? action.resourceType : "resource"
        } has been deleted sucessfully`
      );
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSchools() {
  yield takeEvery(actions.FETCH_SCHOOLS, function*(action) {
    try {
      const resp = yield call(API.common.schools);
      yield put({
        type: actions.FETCH_SCHOOLS_SUCCESS,
        schools: resp.data.schools,
      });
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}
// export function* fetchLessonTests() {
//   yield takeEvery(actions.FETCH_LESSON_TESTS, function*(action) {
//     try {
//       const resp = yield call(API.test.byFilter, action.payload.subject, action.payload.grade, action.payload.school);
//       yield put({
//         type: actions.FETCH_LESSON_TESTS_SUCCESS,
//         tests: resp.data,
//       });
//     } catch (err) {
//       Notification(
//         "error",
//         err && err.error
//           ? err.error.message
//           : "Session timed out. Please login again"
//       );
//     }
//   });
// }
export function* fetchLessonTests() {
  yield takeEvery(actions.FETCH_LESSON_TESTS, function*(action) {
    try {
      const resp = yield call(API.test.list, action.payload);
      yield put({
        type: actions.FETCH_LESSON_TESTS_SUCCESS,
        tests: resp.data?.mcqs,
      });
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchCourseLessons() {
  yield takeEvery(actions.FETCH_COURSE_LESSON, function*(action) {
    try {
      const resp = yield call(API.courses.getUnit, action.payload);
      yield put({
        type: actions.FETCH_COURSE_LESSON_SUCCESS,
        units: resp.data?.course,
      });
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchSchools),
    fork(fetchBooks),
    // fork(saveBook),
    fork(addBookVersionLibrary),
    fork(addBookVersion),
    fork(deleteBook),
    fork(deleteBookVersion),
    fork(fetchSingleBook),
    fork(fetchBookConfigs),
    fork(fetchBonusBooks),
    fork(saveBonusBook),
    fork(fetchSingleBonusBook),
    fork(deleteBonusBook),
    fork(addBonusContentVersion),
    fork(deleteBonusBookVersion),
    fork(fetchUsers),
    fork(fetchLessonTests),
    fork(fetchCourseLessons),
  ]);
}
