import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchSchools() {
  yield takeEvery(actions.FETCH_SCHOOLS, function*(action) {
    try {
      const resp = yield call(API.schools.list);
      yield put({
        type: actions.FETCH_SCHOOLS_SUCCESS,
        schools: resp.data.schools,
      });
      yield call(action?.spinHandler, false);
    } catch (err) {
      yield call(action?.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSingleSchool() {
  yield takeEvery(actions.FETCH_SCHOOL_DETAILS, function*(action) {
    try {
      const resp = yield call(API.schools.details, action.schoolId);
      yield put({
        type: actions.FETCH_SCHOOL_DETAILS_SUCCESS,
        school: resp.data,
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveSchool() {
  yield takeEvery(actions.SAVE_SCHOOL, function*(action) {
    try {
      let resp, message;
      if (action.schoolId) {
        resp = yield call(API.schools.update, action.payload, action.schoolId);
        message = "School updated successfully";
      } else {
        resp = yield call(API.schools.add, action.payload);
        message = "School created successfully";
      }
      const schools = yield call(API.schools.list);
      yield put({
        type: actions.FETCH_SCHOOLS_SUCCESS,
        schools: schools.data.schools,
      });
      yield call(action.cb);
      Notification("success", message);
    } catch (err) {
      yield call(action.cb, "loader");
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteSchool() {
  yield takeEvery(actions.DELETE_SCHOOL, function*(action) {
    try {
      yield call(API.schools.delete, action.schoolId);
      const resp = yield call(API.schools.list);
      yield put({
        type: actions.FETCH_SCHOOLS_SUCCESS,
        schools: resp.data.schools,
      });
      Notification("success", "School deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchSchools),
    fork(saveSchool),
    fork(deleteSchool),
    fork(fetchSingleSchool),
  ]);
}
