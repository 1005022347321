import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import { getToken, getProfile, clearToken } from "../../helpers/utility";
import actions from "./actions";
import appActions from "../app/actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function*(action) {
    try {
      const login = yield call(API.auth.login, action.payload);
      if (action.payload.rememberme) {
        localStorage.setItem("cachedUsername", action.payload.username || action.payload.email);
        localStorage.setItem("cachedUserId", action.payload.password);
      }
      if (login && login.data && login.data.accessToken) {
        yield put({
          type: appActions.CHANGE_CURRENT,
          current: ["dashboard"],
        });
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: login.data.accessToken,
          profile: login.data.user,
        });
        localStorage.setItem("userLoginTime", new Date());
        localStorage.setItem("userData", JSON.stringify(login.data.user));
        yield put(push("/dashboard"));
      } else {
        yield put({ type: actions.LOGIN_ERROR });
      }
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error ?  err.error?.message || err.message || err.error : "Invalid login"
      );
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield localStorage.setItem("id_token", payload.token);
    yield localStorage.setItem("profile", JSON.stringify(payload.profile));
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    try {
      // yield call(API.auth.logout);
      clearToken();
      yield put(push("/"));
    } catch (err) {
      Notification("error", "Session timed out. Please login again");
    }
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken().get("idToken");
    const profile = getProfile().get("profile");
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile,
      });
    }
  });
}

export function* updateProfile() {
  yield takeEvery(actions.UPDATE_PROFILE, function*(payload) {
    const token = getToken().get("idToken");
    const profile = getProfile().get("profile");
    profile.avatar = payload.avatar ? payload.avatar : "";
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(updateProfile),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
