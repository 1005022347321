import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";
import { formatObject } from "../../helpers/utility";

export function* fetchDevices() {
  yield takeEvery(actions.FETCH_DEVICES, function*(action) {
    try {
      const resp = yield call(API.device.list);
      if (resp) {
        yield put({
          type: actions.FETCH_DEVICES_SUCCESS,
          devices: resp.data.devices,
        });

        yield call(action.spinHandler, false);
      }
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}
export function* spaceUpDevice() {
  yield takeEvery(actions.DEVICE_CLEANUP, function*(action) {
    try {
      const space = yield call(API.device.space, action.payload);
      Notification("success", "Successfully Completed...");
      yield call(action.cb);
    } catch (err) {
      yield call(action.cb);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}
export function* fetchDevicesStatus() {
  yield takeEvery(actions.FETCH_DEVICE_STATUS, function*(action) {
    try {
      const summary = yield call(API.device.summary, action.payload);
      if (summary) {
        yield put({
          type: actions.DEVICES_SUMMARY,
          summary,
        });
      }
      yield call(action.spinHandler);
    } catch (err) {
      yield call(action.spinHandler);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveDevice() {
  yield takeEvery(actions.SAVE_DEVICE, function*(action) {
    try {
      let resp, message;
      if (action.deviceId) {
        resp = yield call(API.device.update, action.payload, action.deviceId);
        message = "Device updated successfully";
      } else {
        resp = yield call(API.device.add, action.payload);
        message = "Device created successfully";
      }
      const devices = yield call(API.device.list);
      yield put({
        type: actions.FETCH_DEVICES_SUCCESS,
        devices: devices.data.devices,
      });
      yield call(action.cb);
      Notification("success", message);
    } catch (err) {
      yield call(action.cb);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteDevice() {
  yield takeEvery(actions.DELETE_DEVICE, function*(action) {
    try {
      yield call(API.device.delete, action.deviceId);
      const resp = yield call(API.device.list);
      yield put({
        type: actions.FETCH_DEVICES_SUCCESS,
        devices: resp.data.devices,
      });
      Notification("success", "Device deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchDeviceConfigs() {
  yield takeEvery(actions.FETCH_DEVICE_CONFIGS, function*(action) {
    try {
      const schools = yield call(API.common.schools);
      // const grades = yield call(API.common.grades);
      const roles = yield call(API.common.roles);
      yield put({
        type: actions.FETCH_DEVICE_CONFIGS_SUCCESS,
        schools: schools && schools.data.schools ? schools.data.schools : [],
        grades: [],
        // grades: grades && grades.data.grades ? grades.data.grades : [],
        roles: roles && roles.data.roles ? roles.data.roles : [],
      });
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchUsers() {
  yield takeEvery(actions.FETCH_DEVICE_USERS, function*(action) {
    try {
      const users = yield call(API.users.list, formatObject(action.payload));
      yield put({
        type: actions.FETCH_DEVICE_USERS_SUCCESS,
        users: users && users.data?.students ? users.data?.students : [],
      });
      yield call(action.callback, false);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* assignUser() {
  yield takeEvery(actions.ASSIGN_USER, function*(action) {
    try {
      yield call(API.device.assignUser, action.payload, action.deviceId);
      const resp = yield call(API.device.list);
      yield put({
        type: actions.FETCH_DEVICES_SUCCESS,
        devices: resp.data.devices,
      });
      // const users = yield call(API.users.search, { unAssigned: true });
      const users = yield call(API.users.list);

      yield put({
        type: actions.FETCH_DEVICE_USERS_SUCCESS,
        users: users && users.data.users ? users.data.users : [],
      });
      yield call(action.cb, false);
    } catch (err) {
      yield call(action.cb, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* changeDeviceStatus() {
  yield takeEvery(actions.STATUS_DEVICE, function*(action) {
    try {
      yield call(API.device.update, action.payload, action.deviceId);
      const resp = yield call(API.device.list);
      yield put({
        type: actions.FETCH_DEVICES_SUCCESS,
        devices: resp.data.devices,
      });
      yield call(action.cb, false);
    } catch (err) {
      yield call(action.cb, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* multiUnpin() {
  yield takeEvery(actions.UN_PIN_MULTIPLE, function*(action) {
    try {
      yield call(API.device.multiUnpin, action.payload.ids);
      const resp = yield call(API.device.list);
      yield put({
        type: actions.FETCH_DEVICES_SUCCESS,
        devices: resp.data.devices,
      });
      Notification("success", "Success");
      yield call(action.payload.callBack, false);
    } catch (err) {
      yield call(action.payload.callBack, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchDevices),
    fork(saveDevice),
    fork(deleteDevice),
    fork(fetchDeviceConfigs),
    fork(fetchUsers),
    fork(assignUser),
    fork(changeDeviceStatus),
    fork(multiUnpin),
    fork(fetchDevicesStatus),
    fork(spaceUpDevice),
  ]);
}
