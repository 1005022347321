import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  singleClassroom: null,
  singleClassroomChat: null,
  classrooms: null,
  myClassrooms: null,
  keyBooks: null,
  key: null,
  units:null,
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_SINGLE_CLASSROOM_SUCCESS:
      return state.set("singleClassroom", action.singleClassroom);
      case actions.FETCH_CLASSROOM_UNIT_DETAILS_SUCCESS:
        return state.set("units", action.units);
    case actions.FETCH_SINGLE_CLASSROOM_CHAT_SUCCESS:
      return state.set("singleClassroomChat", action.singleClassroomChat);
    case actions.FETCH_CLASSROOMS_SUCCESS:
      return state.set("classrooms", action.classrooms);
    case actions.FETCH_MY_CLASSROOMS_SUCCESS:
      return state.set("myClassrooms", action.classrooms);
    case actions.ADD_EXISTING_COURSE_SUCCESS:
      return state.set("singleClassroom", action.singleClassroom);
    case actions.EDIT_SINGLE_COURSE_SUCCESS:
      return state.set("singleClassroom", action.singleClassroom);
    case actions.DELETE_SINGLE_COURSE_FILE_SUCCESS:
      return state.set("singleClassroom", action.singleClassroom);
    case actions.SET_FILTER:
      return state.set("key", action.key);
    case actions.SET_FILTER_BOOKS:
      return state.set("keyBooks", action.key);
    case actions.RESET:
      return state.set("singleClassroom", null);
    default:
      return state;
  }
}
