import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* importData() {
  yield takeEvery(actions.IMPORT, function*(action) {
    try {
      let data = new FormData();
      if (action.payload.file && action.payload.file.originFileObj) {
        data.append("file", action.payload.file.originFileObj);
        // data.append("filename", action.payload.file.name);
      }
      // data.append("type", action.payload.type);
      const resp = yield call(API.common.import, data, action.payload.type);
      yield put({
        type: actions.IMPORT_SUCCESS,
        data: resp.data,
      });
      // const notiType =
      //   resp.data && resp.data.resp && resp.data.resp.hasOwnProperty("total")
      //     ? "error"
      //     : "success";
      const notiType =
        resp.data && resp.data.resp && resp.data.resp.rowsHasError > 0
          ? "error"
          : "success";
      Notification(notiType, resp && resp.data.message);
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* encryptPdf() {
  yield takeEvery(actions.ENCRYPT, function*(action) {
    try {
      let data = new FormData();
      if (
        action.payload.file &&
        action.payload.file.length > 0 &&
        action.payload.file.length <= 5
      ) {
        action.payload.file.forEach((file) => {
          data.append("file", file.originFileObj);
          data.append("filename", file.name);
        });
      }
      const resp = yield call(API.common.encrypt, data);

      yield put({
        type: actions.ENCRYPT_DONE,
        file: resp.data.urlArr,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveDoeInfo() {
  yield takeEvery(actions.SET_DEO, function*(action) {
    try {
      // let data = new FormData();
      // if (action.payload.file && action.payload.file.originFileObj) {
      //   data.append("file", action.payload.file.originFileObj);
      //   data.append("filename", action.payload.file.name);
      // }
      // if (action.payload.school) {
      //   data.append("school", action.payload.school);
      // }
      // data.append("doeTitle", action.payload.doeTitle);
      // data.append("allowedUrls", JSON.stringify(action.payload.allowedUrls));
      // data.append("doeUrl", action.payload.doeUrl);
      const resp = yield call(API.common.saveDoe, JSON.stringify(action.payload));
      yield put({
        type: actions.SET_DEO_DONE,
        doeUrl: resp.data.createdDoe.uri,
        doeLogo: resp.data.createdDoe.logo,
      });
      Notification("success", resp && resp.data.message);
      yield call(action.spinHandler, false);
    } catch (err) {
      console.log(err);
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchDoeInfo() {
  yield takeEvery(actions.FETCH_DOE, function*(action) {
    try {
      const resp = yield call(API.common.getDoe);
      yield put({
        type: actions.FETCH_DOE_DONE,
        doe: resp.data.settings,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchConfigs() {
  yield takeEvery(actions.FETCH_CONFIGS, function*(action) {
    try {
      const schools = yield call(API.common.schools);
      yield put({
        type: actions.FETCH_CONFIGS_SUCCESS,
        schools: schools && schools.data.schools ? schools.data.schools : [],
      });
    } catch (err) {
      if (action.spinHandler) yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchFields() {
  yield takeEvery(actions.FETCH_FIELDS, function*(action) {
    try {
      const response = yield call(API.common.fetchFields, action.payload);
      if (response.data && response.data.data)
        yield put({
          type: actions.FETCH_FIELDS_RESULT,
          payload: response.data.data,
        });
      else
        yield put({
          type: actions.FETCH_FIELDS_RESULT,
          payload: [],
        });
    } catch (err) {
      yield put({
        type: actions.FETCH_FIELDS_RESULT,
        payload: [],
      });
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* updateSettings() {
  yield takeEvery(actions.UPDATE_SETTINGS, function*(action) {
    try {
      const response = yield call(API.common.updateSettings, action.payload);

      if (response.data && response.data.message) {
        Notification("success", "Your preference has been saved");
      }

      yield put({
        type: actions.UPDATE_SETTINGS_RESULT,
        payload: true,
      });
    } catch (err) {
      yield put({
        type: actions.UPDATE_SETTINGS_RESULT,
        payload: false,
      });

      Notification(
        "error",
        err && err.error ? err.error.message : "Invalid request"
      );
    }
  });
}

export function* fetchAdminSettings() {
  yield takeEvery(actions.FETCH_ADMIN_SETTINGS, function*(action) {
    try {
      const response = yield call(API.common.fetchAdminSettings);
      if (response.data && response.data.adminSettingsValue)
        yield put({
          type: actions.FETCH_ADMIN_SETTINGS_SUCCESS,
          payload: response.data.adminSettingsValue,
        });
      else
        yield put({
          type: actions.FETCH_ADMIN_SETTINGS_SUCCESS,
          payload: [],
        });
    } catch (err) {
      yield put({
        type: actions.FETCH_ADMIN_SETTINGS_SUCCESS,
        payload: [],
      });
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}
export function* updateAdminSettings() {
  yield takeEvery(actions.UPDATE_ADMIN_SETTINGS, function*(action) {
    try {
      const response = yield call(
        API.common.updateAdminSettings,
        action.payload
      );
      if (response.data && response.message) {
        Notification("success", "Your preference has been saved");
      }
    } catch (err) {
      Notification(
        "error",
        err && err.error ? err.error.message : "Invalid request"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchFields),
    fork(importData),
    fork(encryptPdf),
    fork(updateSettings),
    fork(saveDoeInfo),
    fork(fetchDoeInfo),
    fork(fetchAdminSettings),
    fork(updateAdminSettings),
  ]);
}
